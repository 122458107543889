.loading {
  opacity: 1;
  transition: opacity 3s;
  background: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &.exit-active,
  &.exit-done {
    opacity: 0;
  }
}
