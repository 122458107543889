$time: 2000ms;
$timing: cubic-bezier(0.645, 0.045, 0.355, 1);
$offsetY: -150px;

.intro-button {
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  height: calc(100vh - 3px);
  transition: opacity $time $timing, transform $time $timing;
  transform: translateY($offsetY);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  padding: 10px 20px;

  &.enter-active,
  &.enter-done {
    opacity: 1;
    transform: translateY(0px);
  }
  &.exit-active,
  &.exit-done {
    opacity: 0;
    transform: translateY($offsetY);
  }
}
