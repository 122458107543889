@import '../../variables';

.react-dat-gui {
  right: auto;
  left: 16px;
  z-index: $z-dat-gui;

  *:focus {
    outline: none;
  }
}
