@import '_variables';

@font-face {
  font-family: 'Blur Icons';
  src: url('/fonts/bluricons-regular-webfont.woff2') format('woff2'),
    url('/fonts/bluricons-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'Blur Icons';
  font-size: 40px;

  @include small() {
    font-size: 36px;
  }

  @include desktop() {
    font-size: 44px;
  }

  @include large() {
    font-size: 60px;
  }
}

.icon-arrow:after {
  content: '\E000';
}

.icon-cross:after {
  content: '\E001';
}

.icon-heart:after {
  content: '\E002';
}

.icon-share:after {
  content: '\E003';
}

.icon-muted:after {
  content: '\E005';
}

.icon-unmuted:after {
  content: '\E004';
}

.icon-logo:after {
  content: '\E006';
}

.icon-dots:after {
  content: '\E007';
}

.icon-dots-background:after {
  content: '\E008';
}

.icon-rotate:after {
  content: '\E009';
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-16px);
  }
  60% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounce-small {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
