@import '../../variables';

.controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-controls;
  opacity: 0;

  $time: 1000ms;
  $timing: cubic-bezier(0.645, 0.045, 0.355, 1);
  $offsetY: 100px;

  transform: translateY($offsetY);
  transition-property: opacity, transform;
  transition-timing-function: $timing;
  // weird stuff... between going from .enter-done to .exit-active
  // it seems to remove all classes and reset to just these values:
  transition-duration: $time, $time;

  &.enter-active,
  &.enter-done {
    transition-duration: $time * 2, $time * 2;
    opacity: 1;
    transform: translateY(0px);
  }

  &.exit,
  &.exit-active,
  &.exit-done {
    opacity: 0;
    transform: translateY($offsetY);
  }

  &.exit-done,
  &.enter-done {
    transition-duration: $time, $time;
  }

  &.hide {
    display: none;
  }
}
