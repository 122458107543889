@import '../../variables';

.indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 999px;
  margin-left: -7px;
  margin-top: -7px;
  z-index: $z-indicator;
  opacity: 1;
  // opacity: 0;
  transition: opacity 0.3s;

  @include tablet() {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
  }
}
