@import '../../variables';

.start-screen {
  opacity: 0;
  will-change: opacity;
  transition: opacity 400ms;
}

.fonts-loaded {
  .start-screen {
    opacity: 1;
  }
}

.splash-screen,
.permission-screen {
  background: rgba(255, 255, 255, 0.1);
  color: $font-color-light;

  p,
  .icon-logo {
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 40px;
  }
  .feeld-button.text {
    text-shadow: rgba(0, 0, 0, 0.6) 0 0 15px;
  }

  button {
    color: $font-color-light;
  }

  div.text {
    color: $font-color-light;

    p + p {
      margin-top: 0.38em;
    }
  }

  .overlay-content-container,
  .overlay-content {
    padding: 0;
    height: 100%;
  }

  div.logo {
    .icon {
      position: relative;
      left: calc(var(--vh, 1vh) * 1);
      font-size: calc(var(--vh, 1vh) * 36.2);
    }
    p {
      margin-top: calc(var(--vh, 1vh) * -1.5);
    }
  }

  @include small() {
    .type-m {
      font-size: 7.19vw;
    }
  }

  @include desktop() {
    div.logo {
      .icon {
        font-size: calc(var(--vh, 1vh) * 42);
      }
      p {
        margin-top: calc(var(--vh, 1vh) * -1.5);
      }
    }
  }
}

.permission-screen {
  .overlay-content {
    > div.text {
      height: 63%;
      padding: calc(var(--vh, 1vh) * 8) 5.35vw 0;

      @include tablet() {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: calc(var(--vh, 1vh) * 4) 30px calc(var(--vh, 1vh) * 4);
      }
    }

    > .pill {
      margin: 0 auto;
    }

    > button:last-child {
      position: absolute;
      left: 0;
      bottom: 0.5%;
      width: 100%;
    }

    @include desktop() {
      max-width: 800px;
    }

    @include large() {
      max-width: 1000px;
    }
  }
}

.splash-screen {
  .overlay-content {
    > p.type-xs {
      height: 19%;
      padding-top: 15px;
      box-sizing: border-box;
    }
    > div.logo {
      height: 50%;
    }
    > div.subtitles {
      height: 24%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .subtitle {
        opacity: 1;
        transition: opacity 400ms;

        &.enter {
          opacity: 0;
        }
        &.enter-active {
          opacity: 1;
        }
        &.enter-done {
          opacity: 1;
        }
        &.exit {
          opacity: 1;
        }
        &.exit-active {
          opacity: 0;
        }
        &.exit-done {
          opacity: 0;
        }
      }
    }

    @include desktop() {
      > div.logo {
        height: 55%;
      }
    }
  }
}
