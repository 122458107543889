.Camera {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &.hidden {
    visibility: hidden;
  }
}

.peersContainer {
  canvas,
  video {
    visibility: hidden;
  }
}
