@import '../../../variables';
@import '../../../typography';

@mixin height-stuff($height) {
  height: $height;
  line-height: $height !important;
  border-radius: $height / 2;
}

.hint {
  box-sizing: border-box;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  @include height-stuff(35px);
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(#f0f0f0, 0.85);
  padding: 0 0.7em;
  color: #646464;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s;
  user-select: none;

  font-size: 20px;
  line-height: 27px;
  letter-spacing: (10em / 1000);

  &.enter {
    opacity: 0;
  }
  &.enter-active,
  &.enter-done {
    opacity: 1;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active,
  &.exit-done {
    opacity: 0;
  }
}
