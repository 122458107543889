.stream-handler {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  video {
    max-width: 200px;
  }

  video,
  canvas {
    visibility: hidden;
  }

  &.visible {
    video,
    canvas {
      visibility: visible;
      display: inline-block;
    }
  }
}

.canvas-stream {
  display: none;
}
