$keyWidth: 86px;
$keyHeight: 34px;
$gap: 9px;

.keyboard-controls {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%) scale(0.7);
  display: grid;
  grid-template-columns: ($keyWidth + $gap) ($keyWidth + $gap) ($keyWidth);
  grid-template-rows: ($keyHeight + $gap) ($keyHeight + $gap) ($keyHeight);
  user-select: none;

  > div {
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    background: white;
    border-radius: 7px;
    width: $keyWidth;
    height: $keyHeight;
    opacity: 0.85;
    box-shadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    &.pressed {
      background: #6f6f6f;
      border-color: #606060;
      color: white;

      svg path {
        fill: white;
      }
    }

    svg {
      opacity: 0.7;
    }
  }

  .up-button {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    &:after {
      content: '';
    }

    svg {
      transform: rotate(90deg);
    }
  }

  .down-button {
    grid-column: 2 / 3;
    grid-row: 2 / 2;

    svg {
      transform: rotate(-90deg);
    }
  }

  .left-button,
  .right-button {
    grid-row: 2 / 2;
  }

  .right-button {
    svg {
      transform: rotate(180deg);
    }
  }

  .space-button {
    width: $keyWidth * 3 + $gap * 2;
    grid-row: 3 / 3;
    grid-column: 1 / span 3;
    font-size: 24px;
    letter-spacing: (40em / 1000);
    height: 38px;
    opacity: 0.7;
  }
}
