@import '../../typography';
@import '../../variables';

.feeld-button {
  background: none;
  border: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  padding: 10px;
  color: black;
  user-select: none;

  &:active {
    color: currentColor;
  }

  svg {
    pointer-events: none;
  }

  &.pill {
    @extend .type-ml;
    height: 1.996em;
    padding: 0 1em;
    line-height: 2.2;
    background: black;
    color: white;
    border-radius: 1em;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);

    span.icon {
      font-size: inherit;
      line-height: 0;
      position: relative;
      left: 0.206em;
    }

    &.large {
      @extend .type-l;
      height: 1.885em;
      line-height: 2.1;
    }

    @include desktop() {
      height: 68px;
      line-height: 2;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

      &.large {
        height: 86px;
        line-height: 2;
      }
    }

    @include large() {
      height: 98px;
      line-height: 2.1;

      &.large {
        height: 111px;
      }
    }
  }

  &#info {
    position: absolute;
    right: 0;
    top: -3px;
    z-index: $z-info-button;
    padding: 0 4px 5px 5px;
    margin-left: auto;

    .icon {
      font-size: 59px;

      &:before {
        position: absolute;
        top: 35%;
        content: '';
        left: 20%;
        right: 20%;
        height: 20%;
        background: white;
        z-index: -1;
      }
    }

    @include small() {
      top: 0;

      .icon {
        font-size: 50px;
      }
    }

    @include desktop() {
      top: 2px;
      right: 10px;

      .icon {
        font-size: 72px;
      }
    }
  }

  &#close {
    position: absolute;
    z-index: $z-close-button;
    right: 9px;
    top: 0px;

    @include small() {
      right: 2px;
      top: -2px;
    }
  }

  &#info,
  &#close,
  &#mute,
  &#share {
    letter-spacing: inherit;
    line-height: inherit;
  }
}

.mute-button {
  .color-fill,
  .color-stroke {
    fill: black;
  }
}

.svg-button svg {
  width: 48px;
  height: auto;
}
