@import '../../variables';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-overlay;

  &-content-container {
    box-sizing: border-box;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .centered & {
      justify-content: center;
      align-items: center;
    }

    @include small() {
      padding: 10px 0;
    }
  }

  &-content {
    box-sizing: border-box;
    text-align: center;

    &.centered {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.spaced {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include desktop() {
      max-width: 980px;
      margin: auto;
    }
  }

  &.gradient {
    overflow: hidden;
    $color: white;
    background: linear-gradient(to bottom, $color 100px, rgba($color, 0) 0);

    &:before {
      content: '';
      @include scrimGradient($color);
      z-index: -1;
      position: absolute;
      top: 99px;
      width: 100%;
      bottom: -25vh;
    }
  }
}
