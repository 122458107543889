@import '../../variables';
@import '../../typography';

.info-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $z-info-page;
  background: #ededed;
  overflow: auto;

  nav {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 4px 10px 23px;
    user-select: none;
    pointer-events: none;

    & > * {
      pointer-events: all;
    }

    button + button {
      margin-left: 10px;
    }

    button:last-child {
      margin-left: auto;
    }

    @include small() {
      padding: 0px 6px 20px;

      button + button {
        margin-left: 3px;
      }
    }
  }

  ul {
    list-style: none;
    padding-bottom: 1.65em;

    li {
      margin-top: 0.5em;
      &:before {
        content: '• ';
        margin-right: 0;
      }
    }
  }

  .content {
    line-height: 1.2;
    text-align: center;

    .sticky-container {
      position: sticky;
      padding-top: 0.5em;
      margin: 10.7% 0;
    }

    section {
      text-align: left;
      padding: 0 21px 0 24px;

      .spacing {
        display: inline-block;
        width: 0.1em;
      }

      &:last-child {
        padding-bottom: 3.4em;
      }
    }

    @include small() {
      .type-s {
        font-size: 5vw;
        line-height: 1.35em;
        letter-spacing: (15em / 1000);
      }
    }

    @include tablet() {
      max-width: 550px;
      margin: 0 auto;

      .type-s {
        padding-right: 120px;
      }
    }

    @include desktop() {
      .sticky-container {
        padding-top: 0.7em;
      }

      section:last-child {
        padding-bottom: 5em;
      }
    }

    @include large() {
      max-width: 900px;
    }
  }

  @include tablet() {
    nav {
      position: fixed;
    }

    .content {
      padding-top: 5em;
    }
  }

  @include only-tablet() {
    nav {
      flex-direction: column;
      align-items: flex-start;

      button + button {
        margin-left: 0;
      }

      #mute {
        margin-top: 3px;
      }
    }
  }

  @include desktop() {
    nav {
      button + button {
        margin-left: 14px;
      }
    }
  }

  @include large() {
    nav {
      button + button {
        margin-left: 28px;
      }
    }
    .content {
      padding-top: 9em;
    }
  }
}
