@import '../../variables';

.share-overlay {
  background: #ededed;
  z-index: $z-share-overlay;

  .buttons {
    margin-top: 2em;

    .svg-button + .svg-button {
      margin-left: 8em;
    }
  }

  .clipboard-link {
    margin: 0 !important;
  }
}
