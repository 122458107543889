@import '../../variables';

$time: 2000ms;
$timing: cubic-bezier(0.645, 0.045, 0.355, 1);
$offsetY: -100px;

.notification {
  position: absolute;
  z-index: $z-notification;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 13px 17px 0;
  text-align: left;
  transform: translateY($offsetY);
  opacity: 0;
  transition: opacity $time $timing, transform $time $timing;
  flex: 1;
  letter-spacing: 0;
  user-select: none;

  .hair-space {
    display: inline-block;
    width: 0.15em;
  }

  .feeld-button.text {
    text-align: left;
    transform: translate3d(-11px, -5px, 0);

    @include desktop() {
      text-align: center;
    }
  }

  .icon-arrow {
    position: absolute;
    top: 10px;
    right: 76px;
    animation: bounce 2s infinite;
    font-size: 6.4vw;

    @include small() {
      animation: bounce-small 2s infinite;
      right: 64px;
      top: 12px;
    }

    @include tablet() {
      top: 6px;
      font-size: 32px;
      right: 80px;
    }

    @include desktop() {
      top: 17px;
      right: 100px;
      font-size: 32px;
    }

    @include large() {
      top: 9px;
      right: 100px;
      font-size: 38px;
    }
  }

  .icon-share {
    position: relative;
    top: 7px;
    left: -1px;

    @include small() {
      top: 5px;
    }

    @include desktop() {
      font-size: inherit;
      top: 0;
    }
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;
    transform: translateY(0px);
  }
  &.exit-active,
  &.exit-done {
    opacity: 0;
    transform: translateY($offsetY);
  }

  @include small() {
    padding: 15px 0 0 15px;
  }

  @include desktop() {
    text-align: center;
  }
}

.end-notification {
  div.text {
    padding: 30px 30px;

    @include small() {
      padding-top: 24px;
    }
  }

  .pill {
    margin: 0 auto 10px;
  }

  @include tablet() {
    div.text {
      padding-top: 0;
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .overlay-content {
      max-width: 550px;
      margin: 0 auto;
    }

    .overlay-content-container {
      display: block;
    }
  }

  @include desktop() {
    .overlay-content {
      max-width: 900px;
      margin: 0 auto;
    }
  }
}
