$time: 1000ms;
$timing: cubic-bezier(0.645, 0.045, 0.355, 1);
$offsetY: -150px;

.end-notification {
  transition: opacity $time $timing;
  will-change: opacity;
  opacity: 0;

  .text {
    will-change: transform;
    transition: transform $time $timing;
    transform: translateY($offsetY);
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;

    .text {
      transform: translateY(0px);
    }
  }

  &.exit-active,
  &.exit-done {
    opacity: 0;

    .text {
      transform: translateY($offsetY);
    }
  }
}
