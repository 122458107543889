@import 'reset-css';
@import 'typography';
@import 'icons';
@import 'variables';

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}

body,
button {
  font-family: 'Theinhardt', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
select,
input,
button {
  outline: none;
}

input[type='text'],
textarea {
  font-size: 1em;
}

html.mobile.landscape body:after {
  font-family: 'Blur Icons';
  content: '\E009';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ededed;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30vh;
}
