.overlay.error {
  .overlay-content {
    justify-content: center;
    padding: 15px 18px 15vh;
  }

  .feeld-button.pill {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    bottom: 20px;
  }
}
