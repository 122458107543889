.debug {
  position: absolute;
  left: 5px;
  bottom: 0;
  font-family: 'Courier New', Courier, monospace;
  user-select: none;

  td.success {
    color: green;
  }
  td.error {
    color: red;
  }
}
