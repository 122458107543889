.panner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .joystick {
    box-sizing: border-box;
    position: absolute;
    top: -28px;
    left: -28px;
    width: 56px;
    height: 56px;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #f0f0f0; // TODO: should be hairline...
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 999px;
    transition: opacity 0.1s;

    &.enter {
      opacity: 0;
    }
    &.enter-done {
      opacity: 1;
    }
    &.exit {
      opacity: 1;
    }
    &.exit-done {
      transition: opacity 0.2s 0.4s;
      opacity: 0;
    }
  }

  .position {
    position: absolute;
    box-sizing: border-box;
    top: 16px;
    left: 16px;
    width: 22px;
    height: 22px;
    border: 1px solid #f0f0f0; // TODO: should be hairline...
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 999px;
  }
}
