$font-color-light: #fff;
$font-color-dark: #000;

// z-indices
$z-animation: -1;
$z-indicator: 10;
$z-controls: 20;
$z-notification: 30;
$z-info-button: 40;
$z-overlay: 50;
$z-info-page: 60;
$z-share-overlay: 70;
$z-close-button: 9990;
$z-dat-gui: 9999;

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/

@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0,
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $alpha: alpha($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue * $alpha)
      percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}

// Breakpoints
$phone-width: 350px;
$tablet-width: 750px;
$desktop-width: 1024px;
$large-width: 1600px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin only-tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin small {
  @media (orientation: portrait) and (max-width: #{$phone-width - 1px}), (orientation: landscape) and (max-width: 568px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

.only-small {
  visibility: hidden;

  @include small() {
    visibility: visible;
  }
}

.only-phone {
  visibility: visible;

  @media (min-width: #{$tablet-width}) {
    visibility: hidden;
  }
}

.only-tablet {
  visibility: hidden;

  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    visibility: visible;
  }
}

.only-desktop {
  visibility: hidden;

  @include desktop() {
    visibility: visible;
  }
}

@mixin br-visible() {
  display: block;
  &:before {
    content: none;
  }
}

@mixin br-invisible {
  white-space: pre;
  display: inline;
  &:before {
    content: ' ';
  }
}

span.br-small {
  @include br-invisible();

  @include small() {
    @include br-visible();
  }
}

span.br-phone {
  @include br-visible();

  @media (min-width: #{$tablet-width}) {
    @include br-invisible();
  }
}

span.br-tablet {
  @include br-invisible();

  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @include br-visible();
  }
}

span.br-gt-tablet {
  @include br-invisible();

  @media (min-width: #{$tablet-width}) {
    @include br-visible();
  }
}

span.br-desktop {
  @include br-invisible();

  @include desktop() {
    @include br-visible();
  }
}

span.br-not-desktop {
  @include br-visible();

  @include desktop() {
    @include br-invisible();
  }
}
