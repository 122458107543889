@import '_variables';

@font-face {
  font-family: 'Theinhardt', 'Times New Roman', sans-serif;
  src: url('/fonts/Theinhardt-RegularIta.woff2') format('woff2'),
    url('/fonts/Theinhardt-RegularIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

// green color in XD
.type-xs {
  font-size: 4.8vw;
  line-height: 1.222em;
  letter-spacing: (15em / 1000);

  @include tablet() {
    font-size: 19px;
    line-height: 30px;
    letter-spacing: (15em / 1000);
  }
  @include large() {
    font-size: 25px;
    line-height: 30px;
    letter-spacing: (15em / 1000);
  }
}

// blue color in XD
.type-s {
  font-size: 5.625vw;
  line-height: 1.224em;
  letter-spacing: (15em / 1000);

  @include tablet() {
    font-size: 25px;
    line-height: 33px;
    letter-spacing: (5em / 1000);
  }
  @include large() {
    font-size: 37px;
    line-height: 50px;
    letter-spacing: (5em / 1000);
  }
}

// red color in XD
.type-m {
  font-size: 7.4667vw;
  line-height: 1.145em;
  letter-spacing: (15em / 1000);

  @include tablet() {
    font-size: 33px;
    line-height: 42px;
    letter-spacing: (7em / 1000);
  }

  @include large() {
    font-size: 48px;
    line-height: 58px;
    letter-spacing: (7em / 1000);
  }
}

// purple color in XD
.type-ml {
  font-size: 7.75vw;
  line-height: 1.16em;
  letter-spacing: (15em / 1000);

  @include tablet() {
    font-size: 37px;
    letter-spacing: 0.02em;
    line-height: 34px;
  }

  @include large() {
    font-size: 50px;
    letter-spacing: 0.05em;
    line-height: 34px;
  }
}

// yellow color in XD
.type-l {
  font-size: 9.34vw;
  letter-spacing: (15em / 1000);

  @include tablet() {
    font-size: 47px;
    letter-spacing: 0.02em;
    line-height: 33px;
  }

  @include large() {
    font-size: 60px;
    letter-spacing: 0.02em;
    line-height: 33px;
  }
}

p + p {
  margin-top: 0.58em;
}

em {
  font-style: italic;
}

a,
a:hover,
a:visited,
a:link,
.link {
  color: $font-color-dark;
  pointer-events: all !important;
  text-decoration: none;
  box-shadow: 0 0.04em 0 0.0001em $font-color-dark;

  .ios &,
  .macos & {
    box-shadow: 0 0.07em 0 0.0001em $font-color-dark;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
