@import '../../variables';

main {
  .connecting-overlay {
    text-shadow: #000 0 0 60px;
    transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 1000ms;

    &.exit,
    &.exit-active,
    &.exit-done {
      opacity: 0;
    }
  }

  #info {
    $time: 2000ms;
    $timing: cubic-bezier(0.645, 0.045, 0.355, 1);
    $offsetY: -100px;

    transform: translateY($offsetY);
    opacity: 0;
    transition: opacity $time $timing, transform $time $timing;

    &.enter-active,
    &.enter-done {
      opacity: 1;
      transform: translateY(0px);
    }
    &.exit-active,
    &.exit-done {
      opacity: 0;
      transform: translateY($offsetY);
    }
  }
}
